import fetch from 'isomorphic-fetch'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';

const link = createHttpLink({
    uri: 'http://backend.rushapp.me/rushB',
    fetchOptions: {
        mode: 'cors', // no-cors, *cors, same-origin
     }
  });

export const client = new ApolloClient({
    link,
  cache: new InMemoryCache(),
  fetch
})